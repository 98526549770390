<template>
  <validation-observer ref="addCreditCard">
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Cartão de crédito
                </h5>
                <small class="text-muted">
                  Faremos uma pequena cobrança com devolução automática
                </small>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Número do cartão"
                  label-for="creditCard"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="número do cartão"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="creditCard"
                        v-model="creditCardNumber"
                        class="form-control"
                        :raw="false"
                        :options="creditCardMask"
                        placeholder="1234 5678 1234 5678"
                      />
                      <b-input-group-append is-text>
                        <div class="box-flag d-flex">
                          <b-img
                            :src="require(`@/assets/images/svg/${flag}.svg`)"
                            :alt="flag"
                          />
                          <feather-icon
                            icon="ActivityIcon"
                            class="cursor-pointer"
                            size="1"
                          />
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--  -->
              <b-col md="6">
                <b-form-group
                  label="Vencimento"
                  label-for="validate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vencimento"
                    rules="required"
                  >
                    <cleave
                      id="validate"
                      v-model="validate"
                      class="form-control"
                      :raw="false"
                      :options="validateMask"
                      placeholder="MM/AA"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Código CVV"
                  label-for="cvcCode"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="código cvv"
                    rules="required|integer|max:3|min:3"
                  >
                    <cleave
                      id="cvcCode"
                      v-model="cvcCode"
                      class="form-control"
                      :raw="false"
                      :options="cvvMask"
                      placeholder="123"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--  -->
              <b-col md="12">
                <b-form-group
                  label="Nome do titular do cartão"
                  label-for="managerName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nome do titular"
                    rules="required"
                  >
                    <b-form-input
                      id="managerName"
                      v-model="managerName"
                      placeholder="Nome como está impresso no cartão"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="d-flex mt-1"
              >
                <b-form-checkbox
                  v-model="preferredCard"
                  checked="true"
                  class="custom-control-success"
                  name="check-renovation"
                  switch
                  :disabled="fromUpdatePlan"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>

                <div class="px-1">
                  <h6>Cartão principal
                    <b-img
                      src="@/assets/images/raty/star-on-2.png"
                      alt="svg img"
                    /></h6>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="d-flex py-1 justify-content-end"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <span class="text-nowrap">Salvar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, integer } from '@validations'

import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton, BCard, BImg, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'CreditCardNew',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    Cleave,
    BCard,
    BButton,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  props: {
    fromUpdatePlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creditCardNumber: '',
      cvcCode: '',
      validate: '',
      managerName: '',
      preferredCard: this.fromUpdatePlan,
      // preferredCard: false,
      flag: 'generic',
      creditCardMask: {
        delimiters: [' '],
        blocks: [4, 4, 4, 4],
      },
      validateMask: {
        delimiters: ['/'],
        blocks: [2, 2],
        uppercase: true,
      },
      cvvMask: {
        blocks: [3],
      },
      required,
      integer,
    }
  },
  computed: {
    getFormattedInfoCreditCard() {
      const splitDate = this.validate.split('/')
      const splitName = this.managerName.split(' ')

      return {
        firstName: splitName[0],
        lastName: splitName[splitName.length - 1],
        month: splitDate[0],
        year: splitDate[1],
      }
    },
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  watch: {
    creditCardNumber() {
      const flagByIugu = window.Iugu.utils.getBrandByCreditCardNumber(this.creditCardNumber)
      this.flag = flagByIugu || 'generic'
      return flagByIugu
    },
  },
  mounted() {
    localize('pt_BR')
  },
  setup() {
    async function validationForm() {
      const $refStep = this.$refs.addCreditCard

      try {
        const isValidForm = $refStep.validate()

        if (!isValidForm) return false

        const res = await this.tokenizeIugu()

        return res
      } catch (err) {
        return false
      }
    }

    async function tokenizeIugu() {
      const $this = this
      async function iuguCAllBackHandler(response) {
        if (response.errors) {
          $this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Verifique os dados do cartão.',
              icon: 'DollarSignIcon',
              variant: 'danger',
            },
          })
          return false
        }
        $this.tokenCard = response.id

        const res = await useJwt.addPaymentMethod({
          tokenCard: response.id,
          setAsDefault: $this.preferredCard ? 'true' : 'false',
        })

        if (res) {
          if (!$this.fromUpdatePlan) {
            $this.$router.push({ name: 'credit-card-list' })
          } else {
            $this.$emit('completed')
          }

          $this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cartão adicionado com sucesso!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }

        return true
      }

      try {
        const iuguResponse = await window.Iugu.createPaymentToken(
          window.Iugu.CreditCard(
            this.creditCardNumber,
            this.getFormattedInfoCreditCard.month,
            this.getFormattedInfoCreditCard.year,
            this.getFormattedInfoCreditCard.firstName,
            this.getFormattedInfoCreditCard.lastName,
            this.cvcCode,
          ), iuguCAllBackHandler,
        )
        return iuguResponse
      } catch (err) {
        return false
      }
    }

    return {
      validationForm,
      tokenizeIugu,
    }
  },
}
</script>

<style lang="scss">
.box-flag {
          img {
            min-height: 24px !important;
          }

          svg {
            display: none;
          }
        }
</style>
