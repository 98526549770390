var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"addCreditCard"},[_c('b-overlay',{attrs:{"show":_vm.isAPICallInProgress,"rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8","offset":"2"}},[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Cartão de crédito ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Faremos uma pequena cobrança com devolução automática ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Número do cartão","label-for":"creditCard"}},[_c('validation-provider',{attrs:{"name":"número do cartão","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('cleave',{staticClass:"form-control",attrs:{"id":"creditCard","raw":false,"options":_vm.creditCardMask,"placeholder":"1234 5678 1234 5678"},model:{value:(_vm.creditCardNumber),callback:function ($$v) {_vm.creditCardNumber=$$v},expression:"creditCardNumber"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('div',{staticClass:"box-flag d-flex"},[_c('b-img',{attrs:{"src":require(("@/assets/images/svg/" + _vm.flag + ".svg")),"alt":_vm.flag}}),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ActivityIcon","size":"1"}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Vencimento","label-for":"validate"}},[_c('validation-provider',{attrs:{"name":"vencimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"validate","raw":false,"options":_vm.validateMask,"placeholder":"MM/AA"},model:{value:(_vm.validate),callback:function ($$v) {_vm.validate=$$v},expression:"validate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Código CVV","label-for":"cvcCode"}},[_c('validation-provider',{attrs:{"name":"código cvv","rules":"required|integer|max:3|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cvcCode","raw":false,"options":_vm.cvvMask,"placeholder":"123"},model:{value:(_vm.cvcCode),callback:function ($$v) {_vm.cvcCode=$$v},expression:"cvcCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nome do titular do cartão","label-for":"managerName"}},[_c('validation-provider',{attrs:{"name":"nome do titular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"managerName","placeholder":"Nome como está impresso no cartão","state":errors.length > 0 ? false:null},model:{value:(_vm.managerName),callback:function ($$v) {_vm.managerName=$$v},expression:"managerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex mt-1",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-renovation","switch":"","disabled":_vm.fromUpdatePlan},model:{value:(_vm.preferredCard),callback:function ($$v) {_vm.preferredCard=$$v},expression:"preferredCard"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]),_c('div',{staticClass:"px-1"},[_c('h6',[_vm._v("Cartão principal "),_c('b-img',{attrs:{"src":require("@/assets/images/raty/star-on-2.png"),"alt":"svg img"}})],1)])],1),_c('b-col',{staticClass:"d-flex py-1 justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Salvar")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }